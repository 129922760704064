'use client';
import * as React from 'react';
import { Header as StrapiHeader } from '@strapi-schema';
import { DefaultHeader } from '@/app/layouts/DefaultHeader';
import { useIsLoggedIn } from '@/app/hooks/useIsLoggedIn';

interface HeaderProps {
  data: StrapiHeader;
}

export const Header = (props: HeaderProps) => {
  const { isLoggedIn } = useIsLoggedIn();

  return <DefaultHeader {...props} isAuthenticated={isLoggedIn} />;
};
