import {
  ProductDataType,
  StepsDataType,
  TrackingAttributesNormalizedType,
  WizardCtaNormalizedType,
} from '@/app/api/strapi/types';
import {
  Button,
  CallToAction,
  ImageWithMultipleVersions,
  Media,
  Product,
  Steps,
  TrackingAttributes,
  WizardCta,
} from '@strapi-schema';
import {
  ResponsiveImageType,
  Media as ImageType,
  CallToActionType,
} from '@wearemotivated/design-system/types';

export const getMediaAbsoluteUrl = (
  relativeUrl?: string,
  isVideo?: boolean,
) => {
  if (!relativeUrl) {
    return null;
  }
  // Remove everything before the first "/uploads/" in the URL
  if (
    typeof process.env.NEXT_PUBLIC_CDN_URL !== 'undefined' &&
    relativeUrl.startsWith(process.env.NEXT_PUBLIC_CDN_URL)
  ) {
    relativeUrl = relativeUrl.replace(/.*\/uploads\//, '/uploads/');
  }

  if (relativeUrl.startsWith('http')) {
    return relativeUrl;
  }

  let prefix = isVideo
    ? process.env.NEXT_PUBLIC_CDN_VIDEO_PREFIX
    : process.env.NEXT_PUBLIC_CDN_IMAGE_PREFIX;

  if (relativeUrl.endsWith('.svg')) {
    prefix = '';
  } else if (process.env.MEDIA_URLS_REMOVE_FILENAME_EXTENSIONS == 'true') {
    relativeUrl = relativeUrl.replace(/\.[^/.]+$/, '');
  }

  return `${process.env.NEXT_PUBLIC_CDN_URL || process.env.NEXT_PUBLIC_STRAPI_API_URL}${prefix || ''}${relativeUrl}`;
};

export const normalizeImageData = (imageData?: Media): ImageType => {
  if (!imageData) {
    return { url: '', alt: '' };
  }

  return {
    url: getMediaAbsoluteUrl(imageData?.attributes.url) ?? '',
    alt: imageData?.attributes.alternativeText ?? '',
  };
};

export const normalizeMultipleImageData = (
  imageData: ImageWithMultipleVersions = {},
): ResponsiveImageType => {
  return {
    desktop: normalizeImageData(imageData?.desktop?.data),
    mobile: normalizeImageData(imageData?.mobile?.data?.[0]),
  };
};

export function normalizeTrackingAttributes(
  attrData: TrackingAttributes | TrackingAttributes[] | null | undefined,
) {
  let attr: any = attrData;
  if (typeof attr !== 'object' || !attr) {
    attr = {};
  }
  if (Array.isArray(attr)) {
    attr = attr[0] ?? {};
  }
  if (!attr.customAttributes) {
    attr.customAttributes = {};
  }
  attr.name = String(attr.name);
  attr.id = Number.isNaN(Number(attr.id)) ? 0 : Number(attr.id);

  return attr as TrackingAttributesNormalizedType;
}

function isButtonType(ctaData: any): ctaData is Button {
  return (
    ctaData && typeof ctaData === 'object' && ctaData?.wizardRoute === undefined
  );
}

export const normalizeCallToAction = (
  callToActionData?: CallToAction | Button,
): CallToActionType => {
  if (!callToActionData) {
    return {
      text: '',
      url: '',
    };
  }

  return {
    text: callToActionData?.text || '',
    url: callToActionData?.url || '',
  };
};

// TODO: Fix typescript errors
export const normalizeCtaData = (
  ctaData: any = {},
): WizardCtaNormalizedType => {
  if (isButtonType(ctaData)) {
    return {
      text: ctaData?.text,
      link: `/evaluation/${ctaData.url ?? ''}`,
      trackingAttributes: normalizeTrackingAttributes(
        ctaData?.trackingAttributes,
      ),
    };
  }

  return {
    text: (ctaData as WizardCta)?.text,
    // TODO fix typo
    disclaimerText: (ctaData as WizardCta)?.discalimerText,
    link: `/evaluation/${
      (ctaData as WizardCta)?.wizardRoute?.data?.attributes?.routeId ??
      (ctaData as WizardCta)?.url ??
      ''
    }`,
    trackingAttributes: normalizeTrackingAttributes(
      (ctaData as WizardCta)?.trackingAttributes,
    ),
  };
};

export const normalizeProductData = (productData: Product): ProductDataType => {
  return {
    ...productData.attributes,
    id: productData.id,
    image: normalizeImageData(productData.attributes?.image?.data),
    productSlug: productData?.attributes?.productPage?.data?.attributes?.slug,
    callToAction: {
      text: productData.attributes.name || '',
      url: `/evaluation/${productData?.attributes.treatment?.data.attributes.defaultWizardRoute?.data?.attributes?.routeId}`,
      trackingAttributes: normalizeTrackingAttributes(
        productData?.attributes?.productPage?.data?.attributes
          ?.trackingAttributes,
      ),
    },
  };
};

export const normalizeStepsData = (steps: Steps): StepsDataType => {
  return {
    ...steps,
    steps: steps.steps.map((item) => ({
      ...item,
      image: normalizeMultipleImageData(item.image),
    })),
  };
};
